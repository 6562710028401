import { Fragment } from "react";
import styled from "styled-components";

import Navbar from "../widgets/Navbar";

const MainContentWrapper = styled.main`
    position: relative;
    top: 60px;
`;

const Layout = (props) => {
    return (
        <>
            <Navbar />
            <MainContentWrapper>{props.children}</MainContentWrapper>
        </>
    );
};

export default Layout;
