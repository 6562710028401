import styled from "styled-components";

export const CenterHeaderContainerDiv = styled.div`
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;

    & select {
        font-size: 1.2rem;
        margin-left: 0.5em;
        padding: 0.2em 0;
        border-radius: 5px;
    }
`;
