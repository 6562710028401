import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
// import { GlobalContextState } from "../../hooks/GlobalContext";
import * as util from "../../util/util.js";
import { routePaths } from "../../util/constants";

const { log } = util.getLogger("Navbar");

const NavbarWrapper = styled.div`
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: #159957;
    background-image: linear-gradient(90deg, #4285f4, #34a853);
`;

const Navbar = styled.nav`
    font-weight: 900;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 60px;
    padding: 0 30px;
    max-width: 1200px;
    margin: 0 auto;
`;

const NavbarLinks = styled.div`
    & ul {
        display: flex;
        list-style: none;
        position: relative;
        left: 1rem;
        height: 60px;
        width: 100%;
    }

    & li {
        display: flex;
        align-items: center;
    }

    & li:hover {
        background: #0f6c3d;
        border-radius: 15px;

        // this causes some jumping of menu items
        // border-top: 6px solid transparent;
        // border-bottom: 6px solid transparent;
        // border-left: 1px solid transparent;
        // border-right: 1px solid transparent;
    }

    & li.active {
        border-radius: 15px;

        // orangish (coqueliqot)
        // ----------------------------------------
        // border-top: 6px solid #ff3800;
        // border-bottom: 6px solid #ff3800;
        // border-left: 1px solid #ff3800dd;
        // border-right: 1px solid #ff3800dd;

        // yellowish with transparency
        // ----------------------------------------
        border-top: 6px solid rgba(249, 255, 51, 0.95);
        border-bottom: 6px solid rgba(249, 255, 51, 0.95);
        border-left: 1px solid rgba(249, 255, 51, 0.95);
        border-right: 1px solid rgba(249, 255, 51, 0.95);
    }

    & li a {
        text-decoration: none;
        color: white;
        padding: 1rem 1rem;
        border-radius: 5px;
    }
`;

const NavbarToggleButton = styled.a`
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    padding: 3px;
    border: 1px solid white;
    background-color: transparent;

    & span {
        margin: 2px;
        height: 3px;
        width: 70%;
        background-color: white;
        border-radius: 10px;
    }
`;

const BrandTitle = styled.div`
    font-size: 1.5rem;

    & a,
    & li a {
        text-decoration: none;
        color: white;
        padding: 1rem 1rem;
        border-radius: 5px;
    }

    & a {
        padding: 0;
    }
`;

const BrandTitleSmall = styled.div`
    display: none;

    & a {
        text-decoration: none;
        color: white;
        padding: 1rem 1rem;
        border-radius: 5px;
    }
`;

const NavbarWidget = () => {
    const routerLocation = useLocation();
    log(2, "routePaths=", routePaths, ", routerLocation=", routerLocation);

    return (
        <NavbarWrapper>
            <Navbar>
                <BrandTitle>
                    <a href="index.html">Daily Checkbox</a>
                </BrandTitle>
                <BrandTitleSmall>
                    <a href="index.html">Log</a>
                </BrandTitleSmall>
                {/* TODO: replace toggle button with font awesome: fas fa-bars */}
                <NavbarToggleButton>
                    <span></span>
                    <span></span>
                    <span></span>
                </NavbarToggleButton>
                <NavbarLinks>
                    <ul>
                        {Object.entries(routePaths).map(([key, val]) => (
                            <li key={key} className={key === routerLocation.pathname ? "active" : ""}>
                                <Link to={key}>{val}</Link>
                            </li>
                        ))}
                    </ul>
                </NavbarLinks>
            </Navbar>
        </NavbarWrapper>
    );
};

export default NavbarWidget;
