import React, { useState, useRef } from "react";
import ReactDom from "react-dom";

import Button from "../widgets/Button";
import { ACTIONS } from "../../store/store";
import useReducerEditListModal, { ACTIONS as ACTIONS_MODAL } from "../../hooks/useReducerEditListModal";

import * as util from "../../util/util.js";

import { Container, Overlay, Content, CloseButton, Form, FormField, Label, Input, SubmitButton, ErrorMsg, Pre } from "./EditListModal.styled";

const { log, logmethod: logm, logif } = util.getLogger("EditListModal");

export default function EditListModal({ parentState, parentDispatch, close, submit }) {
    const currentListName = parentState.lists[parentState.currentListId].label;

    log(2, "entering, currentListName=", currentListName);

    const [state, dispatch] = useReducerEditListModal(currentListName);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        log(2, `handleFieldChange: name=${name}, value=${value}`);
        dispatch({ type: ACTIONS_MODAL.SET_NAME, payload: { name, value } });
    };

    const selfClose = (e) => {
        e.preventDefault();
        log(2, "EditListModal.selfClose: ");
        close(null);
    };

    const submitForm = (e, mode) => {
        e.preventDefault();
        const screenErrors = validateNewName(mode);
        log(2, "submitForm: mode=", mode, ", screenErrors=", screenErrors);
        if (screenErrors.size > 0) {
            log(2, "submitForm: errors exist, will not submit, screenErrors=", screenErrors);
            dispatch({ type: ACTIONS_MODAL.SET_ERRORS, payload: screenErrors });
        } else {
            switch (mode) {
                case "ADD":
                    log(2, "submitForm: mode=", mode, ", calling parentDispatch: CREATE_NEW_LIST");
                    parentDispatch({ type: ACTIONS.CREATE_NEW_LIST, payload: state.newListName });
                    break;
                case "RENAME":
                    log(2, "submitForm: mode=", mode, ", calling parentDispatch: RENAME_LIST");
                    parentDispatch({ type: ACTIONS.RENAME_LIST, payload: state.newListName });
                    break;
                case "DELETE":
                    log(2, "submitForm: mode=", mode, ", calling parentDispatch: DELETE_LIST");
                    parentDispatch({ type: ACTIONS.DELETE_LIST });
                    break;
            }
            close(null);
        }
    };

    const validateNewName = (mode) => {
        const screenErrors = new Map();
        if (mode === "DELETE") return screenErrors; // no need for validation if deleting a list.
        const existingListNamesArr = Object.entries(parentState.lists).map(([key, val]) => val.label.toUpperCase());
        const newListNameUpperCase = state.newListName.toUpperCase();
        const findExistingName = existingListNamesArr.find((elem) => elem === newListNameUpperCase);

        log(2, `validateNewName: findExistingName=${findExistingName}`);

        if (findExistingName) {
            screenErrors.set("newListName", `Invalid name - already exists (${state.newListName})`);
        }

        return screenErrors;
    };

    return ReactDom.createPortal(
        <Container>
            <Overlay />
            <Content>
                <CloseButton className="fa fa-close" onClick={selfClose} />
                <Form>
                    <FormField>
                        <Label>List</Label>
                        <Input value={state.newListName} name="newListName" type="text" onChange={handleFieldChange} />
                        <p>{state.errors.get("newListName")}</p>
                    </FormField>
                    <FormField>
                        <Button onClick={(e) => submitForm(e, "ADD")} label="Add" />
                        <Button onClick={(e) => submitForm(e, "RENAME")} label="Rename" />
                        <Button onClick={(e) => submitForm(e, "DELETE")} label="Delete" />
                    </FormField>
                    <FormField>
                        <Pre>
                            state: <br />
                            {util.stringify(state)}
                        </Pre>
                    </FormField>
                </Form>
            </Content>
        </Container>,
        document.getElementById("portal")
    );
}
