import { useQuery, useMutation, useQueryClient } from "react-query";

import * as util from "../util/util.js";

export const QUERY_KEY = "Daily_Checkbox";
const LOCAL_STORAGE_KEY = "Daily_Checkbox";
const DEFAULT_NEW_LIST_NAME = "dflt";
const DEFAULT_NEW_TOPIC_NAME = "-- edit --";

const { log, logmethod: logm, logif } = util.getLogger("store");

export const useDailyCheckboxLocalStorage = () => {
    const { isLoading, isFetching, isError, error, data: state } = useQuery(QUERY_KEY, getData);

    // log(5, "test new log controller (log), isLoading=", isLoading);
    // if (logif(5)) log(5, "test new log controller (logif), isLoading=", isLoading);
    // logm(5, "useDailyCheckboxLocalStorage", "test new log controller (log), isLoading=", isLoading);

    const queryClient = useQueryClient();

    const mutateObj = useMutation(update, {
        onMutate: () => {
            queryClient.cancelQueries(QUERY_KEY);
        },
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries(QUERY_KEY);
            log(5, "useMutation.onSettled: data=", data, ", error=", error, ", variables=", variables, ", context=", context);
        },
    });

    const dispatch = (obj) => mutateObj.mutate(obj);

    return { state, isLoading, isFetching, isError, error, dispatch };
};

export const getData = async () => {
    log(1, "getData: entering");
    const state = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    log(1, "getData: complete,", util.logState(state));
    return state;
};

// TODO: might not need to write data, don't write data when:
//       1) when there is an error.
//       2) when setting modal open flag??? Not sure about this one.
const update = async (dispatchObject) => {
    log(1, "update: entering");
    const state = await getData();
    log(1, `update: entering, type=${dispatchObject.type}`, util.logState(state));
    reducer(state, dispatchObject);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    log(1, `update: complete, type=${dispatchObject.type}`, util.logState(state));
};

export const initData = async () => {
    log(1, "initData: entering");

    let state = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

    if (!state) {
        state = getDefaultState();
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
        console.log("store.initData: wrote initial localStorage state,", util.logState(state));
    }

    log(1, "initData: complete");

    return state;
};

const reducer = (state, action) => {
    log(5, "reducer: entering, type=", action.type, ", payload=", action.payload, ", ", util.logState(state));

    switch (action.type) {
        case ACTIONS.CHANGE_CURRENT_YEAR: {
            const newYear = action.payload;
            state.currentYear = newYear;
            break;
        }

        case ACTIONS.CHANGE_CURRENT_LIST:
            state.currentListId = action.payload;
            break;

        case ACTIONS.MODAL_OPEN_ADD_RENAME_DELETE_LIST:
            state.isModalOpenEditList = true;
            break;

        case ACTIONS.MODAL_CLOSE_ADD_RENAME_DELETE_LIST:
            state.isModalOpenEditList = false;
            break;

        case ACTIONS.CREATE_NEW_LIST:
            createNewList(action.payload, state);
            break;

        case ACTIONS.RENAME_LIST:
            state.lists[state.currentListId].label = action.payload;
            break;

        case ACTIONS.DELETE_LIST: {
            const currentListId = state.currentListId;
            const { beforeListId, afterListId } = findBeforeAfterLists(state, currentListId);
            log(2, `DELETE_LIST: currentListId=${currentListId}, beforeListId=${beforeListId}, afterListId=${afterListId}`);

            delete state.lists[currentListId];

            for (const [key, val] of Object.entries(state.tracking)) {
                log(2, `DELETE_LIST: key=${key}, val=${val}, will delete: state.tracking[${key}].listTracking[${currentListId}]`);
                delete state.tracking[key].listTracking[currentListId];
            }

            ensureDefaultList(state, currentListId, beforeListId, afterListId);

            log(2, `DELETE_LIST: done for currentListId=${currentListId}`);

            break;
        }

        case ACTIONS.CHANGE_TOPIC_NAME: {
            const { topicId, newName } = action.payload;
            state.lists[state.currentListId].topics[topicId].label = newName;
            break;
        }

        case ACTIONS.CHANGE_TOPIC_COLOR: {
            const { topicId, newColor } = action.payload;
            state.lists[state.currentListId].topics[topicId].color = newColor;
            break;
        }

        case ACTIONS.DELETE_TOPIC: {
            const { topicId } = action.payload;
            delete state.lists[state.currentListId].topics[topicId];
            break;
        }

        case ACTIONS.ADD_TOPIC:
            state.lists[state.currentListId].topics[state.nextTopicId++] = { label: DEFAULT_NEW_TOPIC_NAME, color: util.CONFIG.DEFAULT_COLOR };
            log(2, "ADD_TOPIC: nextTopicId=", state.nextTopicId, ", nextListId=", state.nextListId);
            break;

        case ACTIONS.TRACK_SET_DAY_VAL: {
            const { year, listId, topicId, month, day, newValue } = action.payload;

            if (!state.tracking[year]) {
                state.tracking[year] = { listTracking: {} };
            }

            if (!state.tracking[year].listTracking[listId]) {
                state.tracking[year].listTracking[listId] = { topicTracking: {} };
            }

            if (!state.tracking[year].listTracking[listId].topicTracking[topicId]) {
                state.tracking[year].listTracking[listId].topicTracking[topicId] = { topicData: Array(12).fill(0) };
            }

            const currentValue = state.tracking[year].listTracking[listId].topicTracking[topicId].topicData[month];
            const daySelector = util.dayOfMonthBitValues.get(day + 1);

            if (newValue) {
                state.tracking[year].listTracking[listId].topicTracking[topicId].topicData[month] = currentValue | daySelector;
            } else {
                state.tracking[year].listTracking[listId].topicTracking[topicId].topicData[month] = currentValue & ~daySelector;
            }

            const adjustedValue = state.tracking[year].listTracking[listId].topicTracking[topicId].topicData[month];

            if (logif(2))
                log(
                    2,
                    `TRACK_SET_DAY_VAL: adjustedValue=${adjustedValue}, currentValue=${currentValue}, year=${year}, listId=${listId}, topicId=${topicId}, month=${month}, day=${day}, newValue=${newValue}`
                );

            break;
        }

        default:
            log(9, "store.reducer: unknown action=", action);
    }

    log(5, "reducer: complete, type=", action.type, ", payload=", action.payload, ", ", util.logState(state));
};

const findBeforeAfterLists = (state, currentListId) => {
    // sort lists by name (aka label) then find the listId in the position before and after the currently select listId.
    const sortedListsByName = Object.entries(state.lists)
        .map(([key, val]) => ({ key, val: val.label }))
        .sort((a, b) => (a.val < b.val ? -1 : a.val > b.val ? +1 : 0));

    const indexOfCurrentListId = sortedListsByName.findIndex((obj) => obj.key === currentListId);

    log(2, `findBeforeAfterLists: indexOfCurrentListId=${indexOfCurrentListId}, sortedListsByName=`, sortedListsByName);

    const beforeListId = indexOfCurrentListId <= 0 ? null : sortedListsByName[indexOfCurrentListId - 1].key;
    const afterListId = indexOfCurrentListId >= sortedListsByName.length - 1 ? null : sortedListsByName[indexOfCurrentListId + 1].key;

    return { beforeListId, afterListId };
};

const ensureDefaultList = (state, previousListId, beforeListId, afterListId) => {
    if (Object.keys(state.lists).length === 0) {
        // There must always be at least one list.  Initialize with list named "default".
        createNewList(DEFAULT_NEW_LIST_NAME, state);
    } else {
        const newListId = afterListId ?? beforeListId;
        state.currentListId = newListId;
    }
};

const createNewList = (newListName, state) => {
    log(2, `createNewList: entering, newListName=${newListName},`, state);
    const newListId = state.nextListId++;
    state.lists[newListId] = { label: newListName, topics: {} };
    state.currentListId = newListId;
    log(2, `createNewList: complete, newListName=${newListName},`, state);
};

// TODO: disable changing lists if there is an unsaved topic change (or else automatically save it first).
// TODO: Need a modal confirm for deleting topic - or maybe just when topic has events?

const getDefaultState = () => {
    const state = defaultState;
    state.tracking[state.currentYear] = { listTracking: {} };
    state.currentListId = state.nextListId++;
    state.lists[state.currentListId] = { label: DEFAULT_NEW_LIST_NAME, topics: {} };
    return state;
};

const defaultState = {
    currentYear: new Date().getFullYear(),
    currentListId: 0,
    nextListId: 1,
    nextTopicId: 1,
    lists: {},
    tracking: {},
};

export const ACTIONS = {
    CHANGE_CURRENT_YEAR: "CHANGE_CURRENT_YEAR",

    CHANGE_CURRENT_LIST: "CHANGE_CURRENT_LIST",
    CREATE_NEW_LIST: "CREATE_NEW_LIST",
    RENAME_LIST: "RENAME_LIST",
    DELETE_LIST: "DELETE_LIST",

    CHANGE_TOPIC_NAME: "CHANGE_TOPIC_NAME",
    CHANGE_TOPIC_COLOR: "CHANGE_TOPIC_COLOR",
    DELETE_TOPIC: "DELETE_TOPIC",
    ADD_TOPIC: "ADD_TOPIC",

    TRACK_SET_DAY_VAL: "TRACK_SET_DAY_VAL",
};
