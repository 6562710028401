import * as util from "../../util/util.js";

const { log, logmethod: logm, logif } = util.getLogger("MonthHeader");

const MonthHeader = ({ year, month, monthData }) => {
    const [monthName, numberOfDays, firstDayOfWeekIndex, lastDayOfWeekIndex] = monthData[month];

    log(2, `year=${year}, month=${month}, numberOfDays=${numberOfDays}, firstDayOfWeekIndex=${firstDayOfWeekIndex}, lastDayOfWeekIndex=${lastDayOfWeekIndex}`);

    return (
        <div className={`header ${util.getDOW(firstDayOfWeekIndex)}`}>
            <div>
                {monthName} {year}
            </div>

            {[...Array(numberOfDays)].map((item, idx) => (
                <div key={idx}>{idx + 1}</div>
            ))}

            {[...Array(31 - numberOfDays)].map((item, idx) => (
                <div key={numberOfDays + idx + 1} className="next-month">
                    {idx + 1}
                </div>
            ))}
        </div>
    );
};

export default MonthHeader;
