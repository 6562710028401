/* eslint-disable */
import React from "react";
import MonthContainer from "./MonthContainer";
import * as util from "../../util/util.js";

const { log, logmethod: logm, logif } = util.getLogger("TrackingContainer");

function TrackingContainer({ parentState, checkboxGetSet }) {
    const year = parentState.currentYear;
    const listId = parentState.currentListId;
    const allMonthData = util.getAllMonthCharacteristics(year);

    log(2, "allMonthData=", allMonthData);

    return (
        <div className="tracking">
            {/* I made this a section so it wouldn't pick up CSS div width 100% since I want it centered */}
            <section>
                {allMonthData.map((month, month_idx) => (
                    <MonthContainer
                        key={month_idx}
                        parentState={parentState}
                        checkboxGetSet={checkboxGetSet}
                        allMonthData={allMonthData}
                        currentMonth={month_idx}
                    />
                ))}
            </section>
        </div>
    );
}

export default TrackingContainer;
