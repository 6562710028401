import { ButtonIconStyled } from "./ButtonIcon.styled";

const ButtonIcon = ({ onClick, label, spanClass = "fa fa-edit" }) => {
    // In future, may want to allow label to be either string or function
    // where the function will enable dynamically changing label.

    return (
        <ButtonIconStyled onClick={onClick} title={label}>
            <span className={spanClass} />
        </ButtonIconStyled>
    );
};

export default ButtonIcon;
