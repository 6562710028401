/* eslint-disable */
import React from "react";

import { useDailyCheckboxLocalStorage, ACTIONS } from "../store/store.js";
import SelectYearAndListHeader from "../components/ViewYear/SelectYearAndListHeader";
import TrackingContainer from "../components/ViewYear/TrackingContainer";
import { YearViewDiv } from "./YearView04.styled";

import * as util from "../util/util.js";

/* --------------------------------------------------------------------------------
    YearView
----------------------------------------------------------------------------------- */
const { log, logmethod: logm, logif } = util.getLogger("YearView");

const YearView = () => {
    log(2, "entering");

    const { state, isFetching, isLoading, isError, error, dispatch } = useDailyCheckboxLocalStorage();

    if (isLoading) {
        return <span>Loading...</span>;
    }

    if (isError) {
        return <span>Error: {error.toString()}</span>;
    }

    if (!state) {
        return <span>Sorry, no data found in local storage</span>;
    }

    const submitHandler = (e) => {
        e.preventDefault();
        log(2, "submitHandler: entering");
    };

    const changeYear = (e) => {
        const newYear = e.target.value;
        log(2, `changeYear: newYear=${newYear}`);
        dispatch({ type: ACTIONS.CHANGE_CURRENT_YEAR, payload: newYear });
    };

    const changeList = (e) => {
        const newListId = e.target.value;
        log(2, `changeList: newListId=${newListId}`);
        dispatch({ type: ACTIONS.CHANGE_CURRENT_LIST, payload: newListId });
    };

    const checkboxGetSet = (year, listId, topicId, month, day, newValue) => {
        if (newValue !== undefined) {
            log(2, `checkboxGetSet: year=${year}, listId=${listId}, topicId=${topicId}, month=${month}, day=${day}, newValue=${newValue}`);
            dispatch({ type: ACTIONS.TRACK_SET_DAY_VAL, payload: { year, listId, topicId, month, day, newValue } });
        }

        return util.extractDayFromInt(state.tracking[year]?.listTracking[listId]?.topicTracking[topicId]?.topicData?.[month], day + 1);
    };

    return (
        <YearViewDiv>
            <form onSubmit={(e) => submitHandler(e)}>
                <SelectYearAndListHeader parentState={state} selectYear={changeYear} selectList={changeList} />
                {/* ----------------- Render Checkboxes ----------------- */}
                {state.currentYear && state.currentListId && (
                    <TrackingContainer parentState={state} checkboxGetSet={checkboxGetSet}>
                        TrackingContainer
                    </TrackingContainer>
                )}
            </form>
        </YearViewDiv>
    );
};

export default YearView;
