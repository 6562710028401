import * as util from "../../util/util.js";

const { log, logmethod: logm, logif } = util.getLogger("TaskRecord");

const TaskRecord = ({ topicId, parentState, checkboxGetSet, list, topic, month, monthData }) => {
    const year = parentState.currentYear;
    const listId = parentState.currentListId;

    log(2, "month=", month, ", monthData=", monthData, ", topic=", topic);

    const [monthName, numberOfDays, firstDayOfWeekIndex, lastDayOfWeekIndex] = monthData[month];

    return (
        <div className={`event ${util.getDOW(firstDayOfWeekIndex)} ${topic.color}`}>
            <div>
                {/* {topic.label}-{topicId} */}
                {topic.label}
            </div>

            {[...Array(numberOfDays)].map((item, day_index) => (
                <div key={day_index}>
                    <input
                        type="checkbox"
                        defaultChecked={checkboxGetSet(year, listId, topicId, month, day_index)}
                        onChange={(e) => checkboxGetSet(year, listId, topicId, month, day_index, e.target.checked)}
                    />
                </div>
            ))}

            {[...Array(31 - numberOfDays)].map((item, idx) => (
                <div key={numberOfDays + idx + 1}>&nbsp;</div>
            ))}
        </div>
    );
};

export default TaskRecord;
