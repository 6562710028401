import * as util from "../../util/util.js";

const { log, logmethod: logm, logif } = util.getLogger("DayOfWeekHeader");

const DayOfWeekHeader = ({ month, monthData }) => {
    const [monthName, numberOfDays, firstDayOfWeekIndex, lastDayOfWeekIndex] = monthData[month];

    log(2, `monthName=${monthName}, numberOfDays=${numberOfDays}, firstDayOfWeekIndex=${firstDayOfWeekIndex}, lastDayOfWeekIndex=${lastDayOfWeekIndex}`);

    return (
        <div className={`downame ${util.getDOW(firstDayOfWeekIndex)}`}>
            <div> &nbsp; </div>

            {[...Array(31)].map((item, idx) => (
                <div key={idx}> {util.getDOW(idx + firstDayOfWeekIndex)} </div>
            ))}
        </div>
    );
};

export default DayOfWeekHeader;
