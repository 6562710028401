import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Layout from "./components/layout/Layout";
import YearView04 from "./pages/YearView04";
import TopicsList04 from "./pages/TopicsList04";
import Home from "./pages/Home";

import { GlobalContextState } from "./hooks/GlobalContext";

function App() {
    const { routePaths } = GlobalContextState();
    // console.log("App: routePaths=", routePaths);

    return (
        <Layout>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/year" component={YearView04} />
                <Route path="/topics" component={TopicsList04} />
                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </Layout>
    );
}

export default App;
