import React, { useState } from "react";
import { useDailyCheckboxLocalStorage, ACTIONS } from "../store/store.js";
import Topic from "../components/ViewTopics/Topic";
import SelectWidget from "../components/widgets/SelectWidgetObjectMap";
import ButtonIcon from "../components/widgets/ButtonIcon";
import Button from "../components/widgets/Button";
import EditListModal from "../components/modals/EditListModal";
import { CenterHeaderContainerDiv } from "../components/styles/CenterHeaderContainerDiv.styled";
import * as util from "../util/util.js";

const { log, logmethod: logm, logif } = util.getLogger("TopicsList");

const TopicsList = () => {
    log(2, "entering");

    const [isModalOpenEditList, setIsModalOpenEditList] = useState(false);

    const { state, isFetching, isLoading, isError, error, dispatch } = useDailyCheckboxLocalStorage();

    log(2, `isLoading=${isLoading}, isFetching=${isFetching}, isError=${isError}, error=${error},`, util.logState(state));

    if (isLoading) {
        return <span>Loading...</span>;
    }

    if (isError) {
        return <span>Error: {error.toString()}</span>;
    }

    if (!state) {
        return <span>Sorry, no data found in local storage</span>;
    }

    const submitHandler = (e) => {
        e.preventDefault();
        log(2, "submitHandler: entering");
    };

    // Add blank topic label.
    const addTopic = (e) => {
        e.preventDefault();
        log(2, "addTopic: entering");
        dispatch({ type: ACTIONS.ADD_TOPIC });
    };

    // Change topic label.
    const setName = (topicId, newName) => {
        log(2, `setName: topicId=${topicId}, newName=${newName}`);
        dispatch({ type: ACTIONS.CHANGE_TOPIC_NAME, payload: { topicId, newName } });
    };

    // Change topic color.
    const setColor = (topicId, newColor) => {
        log(2, `setColor: topicId=${topicId}, newColor=${newColor}`);
        dispatch({ type: ACTIONS.CHANGE_TOPIC_COLOR, payload: { topicId, newColor } });
    };

    // Delete topic.
    const deleteEntry = (topicId) => {
        log(2, `deleteEntry: topicId=${topicId}`);
        dispatch({ type: ACTIONS.DELETE_TOPIC, payload: { topicId } });
    };

    // Change the selected list in the drop down.
    const changeList = (e) => {
        const newListId = e.target.value;
        log(2, `changeList: newListId=${newListId}`);
        dispatch({ type: ACTIONS.CHANGE_CURRENT_LIST, payload: newListId });
    };

    // pop up modal to add, rename, delete a list.
    const editList = () => {
        log(2, `editList: currentListId=${state.currentListId}`);
        // dispatch({ type: ACTIONS.MODAL_OPEN_ADD_RENAME_DELETE_LIST });
        setIsModalOpenEditList(true);
    };

    // close modal used for add, rename, delete a list.
    const editListClose = () => {
        log(2, `editListClose: currentListId=${state.currentListId}`);
        // dispatch({ type: ACTIONS.MODAL_CLOSE_ADD_RENAME_DELETE_LIST });
        setIsModalOpenEditList(false);
    };

    // process changes to list which is either add, rename or delete.
    const editListSubmitModal = () => {
        log(2, `editListSubmitModal: currentListId=${state.currentListId}`);
    };

    return (
        <div className="topics-list-container">
            <h1>Lists of Topics</h1>
            <form className="topics-list-form" onSubmit={(e) => submitHandler(e)}>
                <CenterHeaderContainerDiv>
                    List:
                    <SelectWidget
                        val={state.currentListId}
                        chgFunc={changeList}
                        optPlaceholderMsg="Select a list"
                        baseObject={state.lists}
                        optListKey=""
                        optListVal=""
                        optListDisplay="label"
                    />
                    <ButtonIcon onClick={editList} label="Add, rename or delete list" spanClass="fa fa-edit" />
                    {isModalOpenEditList && <EditListModal close={editListClose} parentState={state} parentDispatch={dispatch} submit={editListSubmitModal} />}
                </CenterHeaderContainerDiv>
                {Object.keys(state.lists[state.currentListId].topics).map((topicKey, idx) => (
                    <Topic
                        key={topicKey}
                        id={topicKey}
                        lineNumber={idx + 1}
                        text={state.lists[state.currentListId].topics[topicKey].label}
                        color={state.lists[state.currentListId].topics[topicKey].color}
                        setName={setName}
                        setColor={setColor}
                        deleteEntry={deleteEntry}
                    />
                ))}
                <Button onClick={addTopic} label="Add Topic" />
            </form>
        </div>
    );
};

export default TopicsList;
