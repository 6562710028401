import React, { createContext, useContext } from "react";

const GlobalContext = createContext();
export const GlobalContextState = () => {
    return useContext(GlobalContext);
};

// TODO: this is not state - no rerender required on change - so no need to be in context.
const routePaths_DELETEME = { "/year": "Daily Log", "/topics": "Lists" };

const GlobalContextProvider = ({ children }) => {
    return <GlobalContext.Provider value={{ routePaths_DELETEME }}>{children}</GlobalContext.Provider>;
};

export default GlobalContextProvider;
