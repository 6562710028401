/* eslint-disable */
import React from "react";
import Button from "../components/widgets/Button";

import * as util from "../util/util.js";

const { log } = util.getLogger("YearView");

const Home = (props) => {
    log(2, "entering");

    return (
        <div className="home-container">
            <section className="hero">
                <h1>Super Simple Daily Tracker</h1>

                <ul className="checkmark-ul">
                    <li>Create one or more lists of topics to track.</li>
                    <li>Immediately begin tracking daily activity.</li>
                    <li>Absolutely free to use!</li>
                </ul>

                <Button label="Get Started - define a List" onClick={() => props.history.push("/topics")} />

                <div className="notes">
                    <h3>Please Note! This is pre-alpha, not ready for public release.</h3>
                    <ul>
                        <li>There are bugs to fix and design changes coming.</li>
                        <li>Data is stored in local browser, not central database.</li>
                        <li>Clearing cache in browser will delete tracking data.</li>
                    </ul>
                </div>
            </section>

            <div className="tracking-sample">
                <div>
                    <h3>Tracking Screen Example</h3>
                </div>
                <img src="/img/screenshot_tracking_02_no_header.png" alt="screenshot of tracking screen" />
            </div>

            <div className="tracking-sample">
                <div>
                    <h3>List Screen Example</h3>
                </div>
                <img src="/img/screenshot_lists_02_no_header.png" alt="screenshot of List of Topics screen" />
            </div>

            <div className="tracking-sample">
                <div>
                    <h3>Use Cases</h3>
                </div>
                <div className="text">
                    <ul>
                        <li>As a person with New Year's resolutions, track effort on each resolution.</li>
                        <li>As a person on a diet, track frequency of eating sugar, drinking alcohol, exercising.</li>
                        <li>As software developer, track projects worked each day.</li>
                        <li>As manager, track employee absences.</li>
                        <li>As a person who wishes to keep in touch with friends, track personal contact with each friend.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Home;
