import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import GlobalContextProvider from "./hooks/GlobalContext.js";
import * as store from "./store/store.js";

const queryClient = new QueryClient();
// const queryClient = new QueryClient({   // TODO: DEV ONLY!
//     defaultOptions: {
//         queries: {
//             enabled: true,
//             refetchOnWindowFocus: false,
//             retry: false,
//             // staleTime: 600000,
//             // staleTime: 0,
//         },
//     },
// });

queryClient.prefetchQuery(store.QUERY_KEY, store.initData);
// queryClient.prefetchQuery("init", store.initData);

ReactDOM.render(
    // <BrowserRouter basename="/app">
    <BrowserRouter>
        <GlobalContextProvider>
            <QueryClientProvider client={queryClient}>
                <App />
                {/* <ReactQueryDevtools /> */}
            </QueryClientProvider>
        </GlobalContextProvider>
    </BrowserRouter>,
    document.getElementById("root")
);
