export const routePaths = { "/": "Home", "/year": "Tracking", "/topics": "Lists" };

export const day_of_week_by_index = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const month_names = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const CONFIG = {
    DEFAULT_COLOR: "green",
};

export const dayOfMonthBitValues = new Map([
    [1, 1],
    [2, 2],
    [3, 4],
    [4, 8],
    [5, 16],
    [6, 32],
    [7, 64],
    [8, 128],
    [9, 256],
    [10, 512],
    [11, 1024],
    [12, 2048],
    [13, 4096],
    [14, 8192],
    [15, 16384],
    [16, 32768],
    [17, 65536],
    [18, 131072],
    [19, 262144],
    [20, 524288],
    [21, 1048576],
    [22, 2097152],
    [23, 4194304],
    [24, 8388608],
    [25, 16777216],
    [26, 33554432],
    [27, 67108864],
    [28, 134217728],
    [29, 268435456],
    [30, 536870912],
    [31, 1073741824],
]);
