import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    z-index: 1;
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
`;

export const Content = styled.div`
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%);
    background-color: white;
    // border: 14px solid orange;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 40em;
    min-height: 5rem;
    padding: 50px;
    z-index: 11;
    // margin: 0 auto;  // no effect
`;

export const CloseButton = styled.span`
    position: absolute;
    top: 3px;
    right: 7px;
    padding: 0;
    margin: 0;

    font-size: 1.6rem;
    cursor: pointer;
    color: red;
    border: none;
    // border: 2px solid gold;
    background-color: transparent;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
`;

export const FormField = styled.div`
    width: 80%;
    white-space: wrap;
`;

export const Label = styled.label`
    display: block;
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 1rem;
    white-space: nowrap;
    width: 100%;
`;

export const Input = styled.input`
    font-size: 1.2rem;
    margin-right: 0.5em;
    width: 100%;
    border: 1px solid gray;
    border-radius: 2px;
    // outline: 5px solid orange;
`;

export const SubmitButton = styled.button`
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    text-align: center;

    width: 100%;

    // text-decoration: none;
    // font-size: 1rem;

    margin: 1rem 0;
    padding: 0.4em 0.6em;

    color: white;
    border-style: outset;
    border-radius: 6px;
    border: 1px solid blue;
    background-color: #008cba;

    &:hover {
        background-color: #047ca3;
    }

    &:disabled {
        background-color: #08bdda;
        color: silver;
    }
`;

export const ErrorMsg = styled.p`
    color: red;
`;

export const Pre = styled.pre`
    margin: 0.5rem 0;
`;
