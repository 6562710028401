import SelectWidget from "../widgets/SelectWidgetObjectMap";
import SpacerWidget from "../widgets/Spacer";
import { CenterHeaderContainerDiv } from "../styles/CenterHeaderContainerDiv.styled";

const SelectYearAndListHeader = ({ parentState, selectYear, selectList }) => {
    return (
        <CenterHeaderContainerDiv>
            Year:
            <SelectWidget
                val={parentState.currentYear}
                chgFunc={selectYear}
                optPlaceholderMsg="Select a year"
                baseObject={parentState.tracking}
                optListKey=""
                optListVal=""
                optListDisplay=""
            />
            <SpacerWidget width="1em" />
            List:
            <SelectWidget
                val={parentState.currentListId}
                chgFunc={selectList}
                optPlaceholderMsg="Select a list"
                baseObject={parentState.lists}
                optListKey=""
                optListVal=""
                optListDisplay="label"
            />
        </CenterHeaderContainerDiv>
    );
};

export default SelectYearAndListHeader;
