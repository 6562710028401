import React from "react";
import produce from "immer";
import * as util from "../util/util.js";

const { log, logmethod: logm, logif } = util.getLogger("useReducerEditListModal");

const defaultState = {
    newListName: "",
    errors: new Map(), // there are no state errors in this case
};

const useReducerEditListModal = (currentListName) => {
    const [state, dispatch] = React.useReducer(reducer, defaultState, (defaultState) => {
        log(5, "init: entering, currentListName=", currentListName);

        // Apparently this runs the reducer once to establish initial state.
        return reducer(defaultState, {
            type: ACTIONS.INIT,
            payload: { currentListName },
        });
    });

    return [state, dispatch];
};

const reducer = produce((state, action) => {
    log(5, "reducer: entering, action=", action, ", payload=", action.payload);

    state.errors = new Map();

    switch (action.type) {
        case ACTIONS.INIT:
            state.newListName = action.payload.currentListName;
            break;

        case ACTIONS.SET_NAME:
            const { name, value } = action.payload;
            state.newListName = value;
            break;

        case ACTIONS.SET_ERRORS:
            state.errors = new Map(action.payload);
            log(5, "SET_ERRORS: state.errors=", state.errors);
            break;

        default:
            log(9, "unknown action=", action);
    }

    return state;
});

export const ACTIONS = {
    INIT: "INIT",
    SET_NAME: "SET_NAME",
    SET_ERRORS: "SET_ERRORS",
};

export default useReducerEditListModal;
