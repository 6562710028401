import styled from "styled-components";

export const ButtonIconStyled = styled.button`
    cursor: pointer;
    font-size: 1.1em;

    color: darkgray;
    background-color: transparent;
    border: none;
    // border: 1px solid red;
    padding: 0.2rem;
    margin: 0 1.8rem 0 0.2rem;

    &:hover {
        color: teal;
        background-color: transparent;
    }
`;
