import * as util from "../../util/util.js";

const { log, logmethod: logm, logif } = util.getLogger("Topic");

const Topic = (props) => {
    const { id, lineNumber, color, text, setName, setColor, deleteEntry } = props;

    log(2, `lineNumber=${lineNumber}, color=${color}, text=${text}`);

    return (
        <div className="form-group">
            <label>
                {/* {lineNumber}-{id}. */}
                {lineNumber}.
            </label>
            <input type="text" defaultValue={text} className="topic-input" onBlur={(e) => setName(id, e.target.value)} />
            <select value={color} className={color} onChange={(e) => setColor(id, e.target.value)}>
                <option>green</option>
                <option>blue</option>
                <option>cyan</option>
                <option>yellow</option>
                <option>teal</option>
                <option>fuchsia</option>
                <option>red</option>
            </select>
            <span className="delete" onClick={(e) => deleteEntry(id)} />
        </div>
    );
};

export default Topic;
