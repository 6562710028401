import { LOGLEVELS, LOGCOLORS } from "./logconfig";
import { day_of_week_by_index, month_names, CONFIG, dayOfMonthBitValues } from "./constants";

export { CONFIG, dayOfMonthBitValues };

export const getLogger = (name) => {
    let moduleLevel = LOGLEVELS.get(name) || 1;
    let moduleColor = LOGCOLORS.get(name) || "";

    const logif = (currentLevel) => currentLevel >= moduleLevel;

    const logmethod = (currentLevel, method, ...rest) => {
        if (currentLevel >= moduleLevel) {
            if (moduleColor) {
                console.log(`%c${name}.${method}[${moduleLevel}]:`, moduleColor, ...rest);
            } else {
                console.log(`${name}.${method}[${moduleLevel}]:`, ...rest);
            }
        }
    };

    const log = (currentLevel, ...rest) => {
        if (currentLevel >= moduleLevel) {
            if (moduleColor) {
                console.log(`%c${name}[${moduleLevel}]:`, moduleColor, ...rest);
            } else {
                console.log(`${name}[${moduleLevel}]:`, ...rest);
            }
        }
    };

    return { logif, logmethod, log };
};

const { log, logmethod: logm, logif } = getLogger("util");

// The advantage of a closure is that I can have multiple sequences with different starting points.
export const getNextSeqClosure = (seedNumber) => {
    let nextId = isInt(seedNumber) ? parseInt(Number(seedNumber)) : 1;
    if (logif(2)) log(2, `getNextSeqClosure: entering seedNumber=${seedNumber}, nextId=${nextId}`);

    return () => {
        const result = nextId++;
        if (logif(2)) log(2, `getNextSeqClosure.fn(): result=${result}, nextId=${nextId}, seedNumber=${seedNumber}`);
        return result;
    };
};

function isInt(value) {
    if (isNaN(value)) {
        return false;
    }
    var x = parseFloat(value);
    return (x | 0) === x;
}

export const getMonthName = (monthIndex) => {
    return month_names[monthIndex];
};

export const getDOW = (dow_index) => {
    return day_of_week_by_index[dow_index % 7];
};

// month must be 1-based.   Month 2 represents February.
export const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
};

// Returns:
// [
//     ["January", numberOfDays, firstDayOfWeekIndex, lastDayOfWeekIndex],
//     ["February", numberOfDays, firstDayOfWeekIndex, lastDayOfWeekIndex],
//     ["March", numberOfDays, firstDayOfWeekIndex, lastDayOfWeekIndex],
//     ...etc for all 12 months in the year,
// ];
export const getAllMonthCharacteristics = (year) => {
    const monthCharacteristicsArray = [];
    for (let i = 0; i < 12; i++) {
        monthCharacteristicsArray.push(getMonthCharacteristics(year, i + 1));
    }
    return monthCharacteristicsArray;
};

// month must be 1-based.   Month 2 represents February.
export const getMonthCharacteristics = (year, month) => {
    // return new Date(year, month, 0).getDate();
    const dt = new Date(year, month, 0);
    const numberOfDays = dt.getDate();
    const monthName = dt.toLocaleString("default", { month: "long" });
    const lastDayOfWeekIndex = dt.getDay(); // sunday = 0, saturday = 6
    dt.setDate(1);
    const firstDayOfWeekIndex = dt.getDay(); // sunday = 0, saturday = 6

    return [monthName, numberOfDays, firstDayOfWeekIndex, lastDayOfWeekIndex];
};

export const makeNotNull = (inp) => (inp ? inp : "");

// Return object if it is a simple string.
// Otherwise, get first named subfield.
// TODO: check that obj is an object or string and not an array.
export const getObjectField = (obj, ...remainingFields) => {
    let result = null;

    if (obj) {
        if (typeof obj === "string") {
            result = obj;
        } else {
            remainingFields.some((str) => {
                if (obj[str]) {
                    result = obj[str];
                    return true;
                }
                return false;
            });
        }
    }

    // prettier-ignore
    if (logif(2)) log(2, "getObjectFields: result=", result, ", obj=", obj, ", remainingFields=", remainingFields);
    return result;
};

export const convertStringToNumber = (val) => {
    if (!val) return val;
    return isNaN(val) ? val : +val;
    // return val;
};

export const stringify = (obj) => {
    return JSON.stringify(obj, stringifyReplacer, 4);
};

export const stringifyReplacer = (key, value) => {
    if (value instanceof Map) {
        return Array.from(value.entries()); // or with spread: value: [...value]
    } else {
        return value;
    }
};

export const dec2bin = (dec) => {
    return (dec >>> 0).toString(2);
};

export const bin2dec = (bin) => {
    return parseInt(bin, 2).toString(10);
};

export const extractDayFromInt = (intNumber, day) => {
    // day represents day-of-month so should be 1 .. 31.
    if (intNumber === undefined || intNumber === null) {
        if (logif(1)) log(1, `extractDayFromInt: intNumber=${intNumber}, day=${day} short circuit return early false`);
        return false;
    }
    const dayBitVal = dayOfMonthBitValues.get(day);
    const dayVal = intNumber & dayBitVal;
    if (logif(1)) log(1, `extractDayFromInt: intNumber=${intNumber}, day=${day}, dayBitVal=${dayBitVal}, dayVal=${dayVal}, dayVal(bin)=${dec2bin(dayVal)}`);
    return dayVal > 0 ? true : false;
};

export const logState = (state) => {
    if (state) {
        const arr = Object.entries(state.lists).map(([key, val]) => key + ":" + val.label + " (" + Object.keys(val.topics).length + " topics)");
        return `state.currentListId=${state.currentListId}, state.lists=` + JSON.stringify(arr);
    } else {
        return "state=" + state;
    }
};
