const SelectWidget = ({ val, chgFunc, optPlaceholderMsg, baseObject, optListDisplay, optListVal, optListKey }) => {
    const obj = baseObject;
    const keys = Object.keys(baseObject);

    return (
        <select value={val} onChange={chgFunc}>
            {optPlaceholderMsg && !val && (
                <option value="" disabled>
                    {optPlaceholderMsg}
                </option>
            )}
            {keys.map((key) => (
                <option value={optListVal ? obj[key][optListVal] : key} key={optListKey ? obj[key][optListKey] : key}>
                    {optListDisplay ? obj[key][optListDisplay] : key}
                </option>
            ))}
        </select>
    );
};

export default SelectWidget;
