import styled from "styled-components";

const ButtonStyled = styled.button`
    cursor: pointer;
    padding: 0.4rem 0.6rem;
    // margin: 1em;
    margin: 0.6em 0.4em 0.6em 0;

    text-align: center;
    text-decoration: none;
    font-size: 1.4rem;
    color: white;
    border-style: outset;
    border-radius: 6px;
    border: 1px solid gray;
    background-color: #008cba;

    &:hover {
        background-color: #046ca3;
        color: white;
    }

    &:disabled {
        background-color: #82b9cc;
    }
`;

const Button = ({ onClick, label }) => {
    return <ButtonStyled onClick={onClick}>{label}</ButtonStyled>;
};

export default Button;
