export const LOGLEVELS = new Map([
    ["store", 6],
    ["util", 9],
    ["YearView", 9],
    ["TopicsList", 9],
    ["useReducerEditListModal", 9],
    ["Navbar", 9],
    ["TrackingContainer", 9],
    ["TaskRecord", 9],
    ["MonthHeader", 9],
    ["MonthContainer", 9],
    ["DayOfWeekHeader", 9],
    ["Topic", 9],
    ["EditListModal", 9],
]);

export const LOGCOLORS = new Map([
    ["store", "color:red; font-weight: bold; background: yellow;"],
    ["YearView", "color:blue; font-weight: bold; "],
    ["TopicsList", "color:green; font-weight: bold; "],
    // ["Navbar", "padding: 0 20px; background: yellow;"],
]);
