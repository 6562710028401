import React from "react";
import MonthHeader from "./MonthHeader";
import DayOfWeekHeader from "./DayOfWeekHeader";
import TaskRecord from "./TaskRecord";
import * as util from "../../util/util.js";

const { log, logmethod: logm, logif } = util.getLogger("MonthContainer");

function MonthContainer({ parentState, checkboxGetSet, allMonthData, currentMonth }) {
    const year = parentState.currentYear;
    const listId = parentState.currentListId;
    const listObj = parentState.lists[+listId];

    log(2, "year=", year, ", currentMonth=", currentMonth, ", listId=", listId, ", listObj=", listObj);

    return (
        <div>
            <MonthHeader year={year} month={currentMonth} monthData={allMonthData} />
            <DayOfWeekHeader year={year} month={currentMonth} monthData={allMonthData} />

            {Object.keys(listObj.topics).map((topicKey, topic_idx) => (
                <TaskRecord
                    key={topicKey}
                    topicId={topicKey}
                    parentState={parentState}
                    checkboxGetSet={checkboxGetSet}
                    list={listObj}
                    topic={listObj.topics[topicKey]}
                    month={currentMonth}
                    monthData={allMonthData}
                />
            ))}
        </div>
    );
}

export default MonthContainer;
